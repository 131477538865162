import { createTheme, ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { COLORS } from "./static/color";
import { API_CONFIG, APP_PATH, STAGE_NAME } from "./static/common";
import { Amplify } from "aws-amplify";

const Home = React.lazy(() => import("./pages/Home"));
const Generation = React.lazy(() => import("./pages/Generation"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const ReturnPolicy = React.lazy(() => import("./pages/ReturnPolicy"));
const Author = React.lazy(() => import("./pages/Author"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.synapsesBlack,
    },
    secondary: {
      main: COLORS.lightGrey,
    }
  },
  typography: {
    fontFamily: "Poppins",
  },
});

Amplify.configure({
  endpoints: [
    {
      name: "synapses",
      endpoint: API_CONFIG[STAGE_NAME].synapsesApi.endpoint,
      region: "us-east-1",
      custom_header: async () => {
        return {
          "X-API-Key": API_CONFIG[STAGE_NAME].synapsesApi.apiKey,
        };
      },
    },
  ],
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path={APP_PATH.HOME}
            element={
              <Suspense fallback={null}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.GENERATION}
            element={
              <Suspense fallback={null}>
                <Generation />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.PRIVACY_POLICY}
            element={
              <Suspense fallback={null}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.RETURN_POLICY}
            element={
              <Suspense fallback={null}>
                <ReturnPolicy />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.AUTHOR}
            element={
              <Suspense fallback={null}>
                <Author />
              </Suspense>
            }
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
