export const APP_PATH = {
  HOME: "/",
  GENERATION: "/generation",
  PRIVACY_POLICY: "/privacy-policy",
  RETURN_POLICY: "/return-policy",
  AUTHOR: "/author"
};

export const DOMAIN_STAGE_MAP: { [key: string]: string } = {
  localhost: "dev",
  "alpha.synapses.studio": "alpha",
  "beta.synapses.studio": "beta",
  "synapses.studio": "prod",
};

export const DOMAIN_NAME = window.location.hostname;
export const STAGE_NAME = DOMAIN_STAGE_MAP[DOMAIN_NAME];

interface IApiConfig {
  endpoint: string;
  apiKey: string;
}

export const API_CONFIG: {
  [key: string]: {
    synapsesApi: IApiConfig;
  };
} = {
  dev: {
    synapsesApi: {
      endpoint: "https://59hmpobxbd.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "zVYsc3Jtwh5t1LBhGVnlG8APUZ6vc8Ve9LQckStN",
    },
  },
  alpha: {
    synapsesApi: {
      endpoint: "https://59hmpobxbd.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "zVYsc3Jtwh5t1LBhGVnlG8APUZ6vc8Ve9LQckStN",
    },
  },
  beta: {
    synapsesApi: {
      endpoint: "https://jk12lcb471.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "pYF8HYH0iw4VVvDaMpMux4CdvPR5P33O4idWC2uw",
    },
  },
  prod: {
    synapsesApi: {
      endpoint: "https://vf1cm6uiz1.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "2Jlav4H7Xv6KvpZtPSEvl8nD4STdaaAo4FqAz4uh",
    },
  },
};

export const EVENT_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  EXPLORE_CLICK: 'EXPLORE_CLICK',
  TOPIC_CLICK: 'TOPIC_CLICK',
  GENERATE_ART_CLICK: 'GENERATE_ART_CLICK',
  RETRY_CLICK: 'RETRY_CLICK',
  GET_COPY_CLICK: 'GET_COPY_CLICK',
};
